import React from 'react';
import { Link } from 'gatsby'
import { ReactComponent as Next } from './next.svg';

const featuresData = [
	{
		title: 'A marketplace like no other',
		subTitle:
			'We solve a brain drain on labor and help small business keep money moving.',
		description:
			'Each year a static pool of gatekeepers charge higher prices to access expert labor. With remaining brain power locked under contract by multinationals and funneled out of top schools through employment; the \'feeder\' for small businesses to grow large shut down for Covid-stressed startups needing overpriced or unavailable resources. HostJane offers light at the end of the tunnel. A secure trust platform where top engineers, teachers and freelancers can be hired anonymously, in a smarter workspace; a fairer destination where intellectual labor, as virtual services, can be accessed at low prices. We assist companies to make better business decisions.',
		sectionImage: require('./1.jpg'),
		linkText: `Find sellers to partner with`,
		link: 'https://www.hostjane.com/marketplace',
		nextIcon: <Next />,
	},
	{
		title: 'VPS hosting',
		subTitle:
			'Save money and time with a faster VPS hosting solution.',
		description:
			'Run high-use, intensive websites and applications at a lower dollar cost per month. HostJane\'s high-quality VPS and dedicated computes offer 100% SSD storage, Intel® cores, and professional management options to assist small teams close the gap on big competitors. Everything is possible.',
		sectionImage: require('./2.jpg'),
		linkText: `Explore pricing`,
		link: 'https://cloud.hostjane.com',
		nextIcon: <Next />,
	},
	{
		title: 'Vision and mission',
		subTitle: 'To be a turn-key destination for people and small businesses to find affordable freelance and web hosting services.',
		description:
			'HostJane conducts business ethically, honestly, and in full compliance with the law. The platform is committed to your privacy and being transparent about handling customer data. We fulfill our goals when your results improve because of HostJane.',
		sectionImage: require('./3.jpg'),
		linkText: `Find the skills you need`,
		link: 'https://www.hostjane.com/marketplace/skills',
		nextIcon: <Next />,
	},
	{
		title: 'Our values',
		subTitle: 'Women make the world go round.',
		description:
			'<a name="female-equality" class="about-links" href="/about-jane/#the-future">HostJane is for Female equality</a>. We believe a world where Jane Doe\'s intellectual output is given the same infrastructure as males, that human achievement could reverse <a class="about-links" target="_blank" href="https://massivesci.com/articles/sophie-germain-science-hero-fermat/">thousands of years of oppression</a>; and we see a free‐enterprise, private‐property system as the route to that destination. What if <a target="_blank" class="about-links" href="https://www.nytimes.com/1970/09/13/archives/a-friedman-doctrine-the-social-responsibility-of-business-is-to.html">Milton Friedman was right.</a> That the sole and only social responsibility of business is to increase its profits. In the weird, disruptive economics of a marketplace platform that mainstreams female stakeholders as its victors - chasing profits could do more than equalize systemic inequity. It could permanently break glass ceilings in ways they can\'t be resurrected; and do more to abolish gender discrimination than anything else. HostJane is that experiment. Bidding for a world where female genius is not erased—as it was in Sophie Germain\'s tragedy and countless others—we pledge to donate a proportion of our profits to charities fighting the scourge of violence & discrimination against women and girls.',
		sectionImage: require('./4.jpg'),
		linkText: `The entire future hinges on Jane Doe`,
		link: 'https://www.hostjane.com/about-jane/#the-future',
		nextIcon: <Next />,
	},
];

export default featuresData;
