import { graphql } from 'gatsby';
import React from 'react';
import data from '../../../form-data';
import featuresData from '../../../site-data/about-page/features';
import {
	Alert,
	backgroundColor,
	backgroundGradient,
	textColor,
} from '../../../site-data/home/alert';
import AboutBanner from '../../components/AboutPage/AboutBanner';
import AboutNav from '../../components/AboutPage/AboutNav';
import Features from '../../components/AboutPage/Features';
import Hero from '../../components/AboutPage/Hero';
import Opportunities from '../../components/AboutPage/Opportunities';
import DismissableBanner from '../../components/DismissableBanner';
import Layout from '../../components/Layout';
import MainFooter from '../../components/MainFooter';
import SEO from '../../components/SEO';
import heroData from '../../../site-data/about-page/hero';
import aboutBannerData from '../../../site-data/about-page/aboutBanner';
import opportunitiesData from '../../../site-data/about-page/opportunities';
import aboutNavData from '../../../site-data/about-page/aboutNav';
import { ReactComponent as Flame } from '../../svgs/nav/HostJane.svg';

const AboutPage = props => {
	return (
		<Layout {...props} hasFooter={false} banner={data.banner} fullWidth>
			<SEO
				title="About · HostJane"
				description="HostJane is how people work remote. We're supporting a community where people can buy and sell skills, work together and host faster websites."
			/>
			<DismissableBanner
				backgroundColor={backgroundColor}
				textColor={textColor}
				backgroundGradient={backgroundGradient}
			>
				{Alert === null ? null : <Alert />}
			</DismissableBanner>
			<AboutNav
				smallNavIcon
				navLogo={<Flame />}
				navTitle={aboutNavData.navTitle}
				navLinks={aboutNavData.navLinks}
				btnText={aboutNavData.btnText}
				btnLink={aboutNavData.btnLink}
			/>
			<Hero
				title={heroData.title}
				titleLogo={heroData.titleLogo}
				heroGif={heroData.heroGif}
				topDescription={heroData.topDescription}
				bottomDescription={heroData.bottomDescription}
			/>
			{featuresData.map((featureData, index) => {
				return (
					<Features
						title={featureData.title}
						subTitle={featureData.subTitle}
						description={featureData.description}
						sectionImage={featureData.sectionImage}
						linkText={featureData.linkText}
						link={featureData.link}
						nextIcon={featureData.nextIcon}
						reverse={index % 2 === 1}
					/>
				);
			})}
			<AboutBanner
				boxes={aboutBannerData.boxes}
				bottomDescription={aboutBannerData.bottomDescription}
				bottomLinkText={aboutBannerData.bottomLinkText}
				bottomLink={aboutBannerData.bottomLink}
				publishDate={aboutBannerData.publishDate}
			/>
			<Opportunities
				opportunities={opportunitiesData.opportunities}
				btnText={opportunitiesData.btnText}
				btnLink={opportunitiesData.btnLink}
				btnIcon={opportunitiesData.btnIcon}
			/>
			<MainFooter />
		</Layout>
	);
};

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
				nav {
					path
					icon
					title
					desc
				}
			}
		}
	}
`;

export default AboutPage;
