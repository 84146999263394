import React from 'react';
import styled from 'styled-components';

const HeroWrapper = styled.section`
	&.hostjane-main-About-Hero {
		width: 100%;
		position: relative;
		background-repeat: no-repeat, repeat;
		background-position: center bottom;
		margin-bottom: 6px;
		background-color: #ffffff;
		padding-top: 10px;
		.hostjane-main-About-Hero__section-content {
			margin-left: auto;
			margin-right: auto;
			width: 1012px;
			@media screen and (max-width: 1192px) {
				// width: calc(1192px - 110px);
				width: 900px;
			}
			@media screen and (max-width: 1024px) {
				width: 692px;
			}
			@media screen and (max-width: 850px) {
				width: 87.5%;
			}
			.hostjane-main-About-Hero__section-content__text-wrap {
				&__logo {
					svg {
						width: 260px;
						margin-right: 4px;
						transform: translateY(3px);
					}
				}
				padding-top: 30px;
				padding-bottom: 90px;
				margin-left: auto;
				margin-right: auto;
				float: none;
				h1 {
					margin: 0;
					padding: 0;
					margin-bottom: 30px;
					text-align: center;
					font-size: 45px;
					line-height: 1.08365;
					font-weight: 800;
					margin-left: auto;
					margin-right: auto;
					float: none;
					width: 100%;
					color: #000;
				}
				p {
					margin: 0;
					padding: 0;
					text-align: center;
					color: #000;
					font-size: 24px;
					line-height: 1.7;
					font-weight: 400;
					letter-spacing: 0.009em;
					margin-left: auto;
					margin-right: auto;
					float: none;
					box-sizing: border-box;
					position: relative;
					z-index: 1;
					min-height: 2px;
					width: 66.66%;
				}
				@media screen and (max-width: 992px) {
					h1 {
						font-size: 45px;
						line-height: 1.125;
						font-weight: 800;
						letter-spacing: 0.004em;
					}
					p {
						margin-top: 15px;
						width: 83.33333%;
					}
				}
				.hostjane-main-About-Hero__section-content__gif {
					background-image: url(${props => props.heroGif});
					height: 500px;
					max-width: 800px;
					margin: 0 auto;
					background-position: center center;
					background-size: cover;
					background-repeat: no-repeat;
					@media screen and (max-width: 576px) {
						height: 242px;
					}
				}
			}
		}
	}
`;
const Hero = ({
	title,
	titleLogo,
	heroGif,
	topDescription,
	bottomDescription,
}) => {
	return (
		<HeroWrapper className="hostjane-main-About-Hero" heroGif={heroGif}>
			<div className="hostjane-main-About-Hero__section-content">
				<div className="hostjane-main-About-Hero__section-content__text-wrap">
					<h1 className="hostjane-main-About-Hero__section-content__text-wrap__logo">
						{titleLogo} {title}
					</h1>
					<p>{topDescription}</p>
					<div className="hostjane-main-About-Hero__section-content__gif" />
					<p>{bottomDescription}</p>
				</div>
			</div>
		</HeroWrapper>
	);
};

export default Hero;
