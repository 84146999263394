import React from 'react';
import { ReactComponent as Logo } from '../../../static/logo-svg-code.svg';

const heroData = {
	title: 'keeps money moving',
	titleLogo: <Logo />,
	topDescription:
		'A buyer in Florida. A seller in Tokyo. Their worlds touch on Jane\'s platform, simply and productively. Solve problems with people working from anywhere, in a workspace that eases cross-border payments into one timezone.',
	bottomDescription:
		"HostJane's boards, lists, and cards raise the bar for online work far beyond a chat. Achieve results that disrupt competitors much bigger than you. Launched in the coronavirus pandemic, facing the deepest global recession since WW2; HostJane shares a purpose with startups to generate bread and butter income as the underdog. HostJane is the small payments platform to #keepmoneymoving.",
	heroGif: 'https://www.hostjane.com/assets/a-connected-world.gif',
};

export default heroData;
