import React from 'react';
import styled from 'styled-components';

const FeaturesWrapper = styled.section`
 &.hostjane-main-Features {
  h1,
  h2,
  h3,
  p {
   margin: 0;
  }
  margin: 0 auto;
  max-width: 1400px;
  width: 100%;
  padding-top: 10px;
  display: block;
  @media screen and (max-width: 992px) {
   margin-bottom: 15px;
  }
  main {
   margin-left: auto;
   margin-right: auto;
   width: 100%;
   list-style: none;
  }
  .hostjane-main-Features_feature-list {
   display: flex;
   &.reversed {
    flex-direction: row-reverse;
    @media screen and (max-width: 992px) {
     flex-direction: column-reverse;
    }
   }
   background: #fff;
   z-index: 1;
   &:before {
    content: ' ';
    display: table;
   }
   .about-links {
	border-bottom: 2px dashed #35469C;
   }

   @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
   }
   .hostjane-main-Features_feature-list__copy-box {
    max-width: 100%;
    display: flex;
    -webkit-box-flex: 1;
    flex: 1 1 0;
    -webkit-box-align: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    margin-top: 15px;
    margin-bottom: 6px;
    background-color: #fafafa;
    padding: 60px 0px;
    @media screen and (max-width: 992px) {
     display: block;
     margin: 0;
    }
    .hostjane-main-Features_feature-list__copy-box__item-content {
     margin: auto;
     width: 430px;

@media screen and (max-width: 1192px) {
      width: 80%;
     }
     .hostjane-main-Features_feature-list__copy-box__item-content__small-headline {
      padding-bottom: 20px;
      h2 {
       font-size: 21px;
       line-height: 1.38105;
       font-weight: 400;
       letter-spacing: 0.011em;
       color: #333;
      }
      h3 {
       margin-top: 0.4em;
       font-size: 40px;
       line-height: 1.1;
       font-weight: 600;
       color: #111;
       letter-spacing: 0em;
      }
      @media screen and (max-width: 992px) {
       h2 {
        font-size: 19px;
        line-height: 1.42115;
        font-weight: 400;
        letter-spacing: 0.012em;
       }
       h3 {
        font-size: 28px;
        line-height: 1.14286;
        font-weight: 600;
        letter-spacing: 0.007em;
       }
      }
     }
	 p {
		font-size: 20px;
		line-height: 1.8;
		font-weight: 400;
		letter-spacing: -0.022em;
		color: #000;
	   }
	   li {
		margin-top: 0.8em;
		a {
		 text-decoration: none;
		 color: #0070c9;
		 span {
		  &:first-of-type {
		  }
		  &:last-of-type {
		  }
		 }
		 span {
		  svg {
		   width: 10px;
		   height: 9px;
		   fill: #0070c9;
		   stroke: #0070c9;
		   stoke-width: 17px;
		   margin-left: 5px;
		  }
		 }
		}
	   }
	  }
	 }
	 figure {
	  max-width: 100%;
	  display: -webkit-box;
	  display: flex;
	  -webkit-box-flex: 1;
	  flex: 1 1 0;
	  -webkit-box-align: center;
	  align-items: center;
	  background-size: cover;
	  background-position: center;
	  min-height: 550px;
	  margin: 0;
	  margin-top: 15px;
	  margin-bottom: 6px;
	  background-image: url(${props => props.backgroundImage});
	  width: 700px;
	  height: 700px;
	  @media screen and (max-width: 992px) {
	   min-height: 370px;
	   width: 100%;
	   margin: 0;
	  }
	 }
	}
   }
  `;
  const Features = ({
	reverse,
	title,
	subTitle,
	description,
	sectionImage,
	linkText,
	link,
	nextIcon,
   }) => {
	return (
	 <FeaturesWrapper
	  className="hostjane-main-Features"
	  backgroundImage={sectionImage}
	 >
	  <main>
	   <div
		className={`hostjane-main-Features_feature-list ${
		 reverse && 'reversed'
		}`}
	   >
		<div className="hostjane-main-Features_feature-list__copy-box">
		 <div className="hostjane-main-Features_feature-list__copy-box__item-content">
		  <div className="hostjane-main-Features_feature-list__copy-box__item-content__small-headline">
		   <h2>{title}</h2>
		   <h3>{subTitle}</h3>
		  </div>
		  <p
		   dangerouslySetInnerHTML={{
			__html: description,
		   }}
		  />
		  <li>
		   <a alt="" href={link} target="__blank">
			<span>{linkText}</span>
			<span>{nextIcon}</span>
		   </a>
		  </li>
		 </div>
		</div>
		<figure className="hostjane-main-Features__feature-list__image-box" />
	   </div>
	  </main>
	 </FeaturesWrapper>
	);
   };
   
   export default Features;