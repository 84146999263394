import React from 'react';
import styled from 'styled-components';

const OpportunitiesWrapper = styled.section`
	max-width: 1012px;
	margin: 0 auto;
	margin-bottom: 120px;
	.hostjane-main-Opportunities {
		max-width: 1012px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		padding-right: 16px;
		padding-left: 16px;

		@media screen and (max-width: 576px) {
			flex-direction: column;
			padding-right: 8px;
			padding-left: 8px;
			margin-right: 0;
			margin-left: 0;
		}
		.hostjane-main-Opportunities__content {
			width: 50%;

			padding-right: 24px !important;
			padding-left: 24px !important;
			margin-bottom: 24px !important;
			font-size: 18px;
			a {
				font-size: 20px;
				text-decoration: none;
				color: #0366d6;
				line-height: 1.8;
				font-weight: bold;
				.bump-link {
					margin-left: 5px;
					svg{
						fill: #0366d6;
						width; 15px;
						height: 15px;
					}
					color: inherit;
					opacity: 0;
					transform: translateX(0);
					display: inline-block;
					transition: opacity 0.2s, transform 0.2s;
				}
				&:hover {
					.bump-link {
						opacity: 1;
						transform: translateX(3px);
					}
				}
			}
			p {
				margin-top: 0;
				margin-bottom: 10px;
				color: #586069;
				line-height: 1.5;
			}
			@media screen and (max-width: 576px) {
				width: 100%;
				a {
					font-size: 18px;
				}
				p {
					font-size: 16px;
				}
			}
		}
	}
	hr {
		height: 0;
    margin: 15px 0;
    overflow: hidden;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #dfe2e5;
    margin-top: 48px !important;
    margin-bottom: 48px !important;

	}
	.hostjane-main-Opportunities__button {
		text-align: center;
		a {

			svg {
				width: 32px;
				height: 32px;
				margin-right: 9px;
				margin-top: 2px;
			}
			display: inline-block;
			padding: 12px 24px;
			font-size: 17px;
			font-weight: 500;
			color: #fff;
			white-space: nowrap;
			vertical-align: middle;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			background-color: #1074e7;
			border: 1px solid #1074e7;
			border-radius: 6px;
			transition: 0.2s;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			color: #1074e7;
			background-color: hsla(0, 0%, 100%, 0);
			border-color: rgba(16, 116, 231, 0.5);
			text-decoration: none;
			&:hover {
				color: #0366d6;
				text-decoration: none;
				background-color: hsla(0, 0%, 100%, 0);
				border-color: #1074e7;
			}
			display: flex;
			align-items: center;
			justify-content: center;
			width: 200px;
			margin: 0 auto;
		}
	}
`;
const Opportunities = ({ opportunities, btnText, btnLink, btnIcon }) => {
	return (
		<OpportunitiesWrapper>
			<div className="hostjane-main-Opportunities">
				{opportunities.map(
					({ title, description, nextIcon, titleLink }, key) => {
						return (
							<div
								key={key}
								className="hostjane-main-Opportunities__content"
							>
								<a href={titleLink} target="__blank">
									{title}
									<span className="bump-link">
										{nextIcon}
									</span>
								</a>
								<p>{description}</p>
							</div>
						);
					}
				)}
			</div>
			<hr />
			<div className="hostjane-main-Opportunities__button">
				<a href={btnLink}>
					{btnIcon} {btnText}
				</a>
			</div>
		</OpportunitiesWrapper>
	);
};

export default Opportunities;
