import React from 'react';
import styled from 'styled-components';

const BannerWrapper = styled.section`
	@media only screen and (max-width: 576px) {
		padding-right: 40px !important;
		padding-left: 40px !important;
	}
	.hostjane-main-About-Banner_more-info {
		max-width: 1012px;
		margin: 0 auto;
		padding: 0 16px;
		p {
			&:first-of-type {
				margin-bottom: 40px !important;
				text-align: center !important;
				font-size: 12px !important;
				color: #6a737d !important;
				margin-top: 0;
			}
			&:last-of-type {
				margin-bottom: 40px !important;
				text-align: center !important;
				color: #586069 !important;
				line-height: 1.5;
				a {
					color: #1074e7;
					text-decoration: none !important;
					box-shadow: 0 1px 0 0 rgba(16, 116, 231, 0.5);
					transition: 0.2s;
					background-color: initial;
					text-align: center !important;
				}
			}
		}
		hr {
			height: 0;
			margin: 15px 0;
			overflow: hidden;
			background: transparent;
			border: 0;
			border-bottom: 1px solid #dfe2e5;
			margin-top: 48px !important;
			margin-bottom: 48px !important;
		}
	}
	.hostjane-main-About-Banner_boxes {
		display: flex;
		margin-bottom: 24px;
		border-radius: 6px;
		text-align: center;
		flex-wrap: wrap;
		box-shadow: 0 1px 0 rgba(27, 31, 35, 0.04);
		max-width: 1012px;
		margin: 0 auto;
		margin-bottom: 24px;
		margin-top: 90px;
		@media only screen and (max-width: 576px) {
			flex-direction: column;
		}
		.hostjane-main-About-Banner_boxes_content-wrap {
			border-bottom: 0;
			border: 1px solid #e1e4e8;
			width: 33.33333%;
			text-align: center;
			line-height: 1.5;
			@media only screen and (max-width: 576px) {
				width: 100%;
				border-bottom: 1px solid #e1e4e8;
			}
			.hostjane-main-About-Banner_boxes_content-wrap_content {
				padding: 40px 16px 40px 16px;
				@media only screen and (max-width: 576px) {
					padding: 24px 16px 24px 16px;
				}
				span {
					display: block;
					&:first-of-type {
						font-size: 24px;
						font-weight: bold;
						margin-bottom: 0;
					}
					&:last-of-type {
						color: #586069;
						font-size: 14px;
					}
				}
				@media only screen and (max-width: 576px) {
					span {
						&:first-of-type {
							font-size: 22px;
						}
						&:last-of-type {
							font-size: 14px;
						}
					}
				}
			}
		}
	}
`;
const AboutBanner = ({
	boxes,
	bottomDescription,
	bottomLinkText,
	bottomLink,
	publishDate,
}) => {
	return (
		<BannerWrapper className="hostjane-main-About-Banner">
			<div className="hostjane-main-About-Banner_boxes">
				{boxes.map(({ title, subTitle }, key) => {
					return (
						<div
							key={key}
							className="hostjane-main-About-Banner_boxes_content-wrap"
						>
							<div className="hostjane-main-About-Banner_boxes_content-wrap_content">
								<span>{title}</span>
								<span>{subTitle}</span>
							</div>
						</div>
					);
				})}
			</div>
			<div className="hostjane-main-About-Banner_more-info">
				<p>{publishDate}</p>
				<p>
					{bottomDescription}
					<br />
					<a href={bottomLink}>{bottomLinkText}</a>
				</p>
				<hr />
			</div>
		</BannerWrapper>
	);
};

export default AboutBanner;
