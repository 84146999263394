const aboutBannerData = {
	boxes: [
		{ title: '08/08/2018', subTitle: 'Incorporation' },
		{ title: 'Wyoming', subTitle: 'Registered business' },
		{ title: '17*', subTitle: 'Datacenter locations' },
	],
	publishDate: '* As of June 2022',
	bottomDescription:
		'The official HostJane Discord server supports crypto enthusiasts and talented freelancers',
	bottomLink: 'https://discord.gg/5rS6Tvd',
	bottomLinkText:
		'Connect to other HostJane users and make bright ideas happen',
	internal: false,
};

export default aboutBannerData;
